import React, { useState, useRef, useEffect } from "react";
import bgLogin from "../../assets/images/bgLogin.png";
import loadImg from "../../assets/images/loadImg.png";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Axiosregister } from "../../services/authServices";
import Notiflix from "notiflix";
import { useSearchParams } from "react-router-dom";
import "./register.css";

function Register() {
  let navigate = useNavigate();
  const { register, getValues, handleSubmit, formState: { errors } } = useForm();
  const [searchParams, setSearchParams] = useSearchParams();

  const formData = new FormData();

  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [avt, setAvt] = useState(null);

  formData.append('cardFrontImage', front);
  formData.append('cardBackImage', back);
  formData.append('cardImage', avt);
  if (searchParams.get("ref")) {
    formData.append('code', searchParams.get("ref"));
    localStorage.setItem("ref", searchParams.get("ref"))
  } else {
    if (localStorage.getItem("ref")) {
      formData.append('code', localStorage.getItem("ref"));
    } else {
      formData.append('code', "L1191D");
    }
  }

  const onSubmit = (data) => {
    formData.append('fullName', data.name);
    formData.append('email', data.email.toLowerCase());
    formData.append('password', data.password);
    formData.append('phone', data.phone);
    Notiflix.Loading.pulse();

    Axiosregister(formData)
      .then((data) => {
        Notiflix.Notify.success('Đăng ký thành công')
        Notiflix.Loading.remove();
        navigate('/login')
      })
      .catch((err) => {
        switch (err.response.data.code) {
          case "USER_ALREADY_EXIST":
            Notiflix.Notify.failure('Tài khoản đã tồn tại')
            break;
          case "BAD_REQUEST":
            Notiflix.Notify.failure('Số điện thoại không hợp lệ')
            break;
          default:
            Notiflix.Notify.failure('Đăng ký không thành công')
            break;
        }
        Notiflix.Loading.remove();
      })
  }

  return (
    <div className="bg-white relative lg:py-40 sm:w-full">
      <div className="flex flex-col items-center justify-between pt-0 pr-10 pb-0 pl-10 mt-0 mr-auto mb-0 ml-auto max-w-7xl xl:px-5 lg:flex-row">
        <div className="flex flex-col items-center w-full pt-5 lg:pr-10 pb-20 lg:pl-10 lg:pt-20 lg:flex-row">
          <div className="w-full bg-cover relative max-w-md lg:max-w-2xl lg:w-7/12">
            <div className="flex flex-col items-center justify-center w-full h-full relative lg:pr-10">
              <img src="https://img.bocryptos.com/logo-bocryptos.png" alt="logo" className="w-1/2" />
            </div>
          </div>
          <div className="w-full mt-20 mr-0 mb-0 ml-0 relative z-10 max-w-2xl lg:mt-0 lg:w-5/12">
            <div className="flex flex-col items-start justify-start pt-10 pr-10 pb-10 pl-10 bg-white shadow-2xl rounded-xl
          relative z-10">
              <p className="w-full text-4xl font-medium text-center leading-snug font-serif">Đăng Ký Bocryptos</p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full mt-6 mr-0 mb-0 ml-0 relative space-y-8">
                  <div className="relative">
                    <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Họ và tên</p>
                    <input type="text" className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md"
                      name="name"
                      required
                      {...register('name', {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="relative">
                    <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
                    <input type="mail" className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md"
                      name="email"
                      required
                      {...register("email", {
                        pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
                      })}
                    />
                    {errors?.email?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập email</p>}
                    {errors?.email?.type === "pattern" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập đúng định dạng email</p>}
                  </div>
                  <div className="relative">
                    <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Số điện thoại</p>
                    <input type="phone" className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md"
                      name="numberPhone"
                      required
                      {...register("phone", {
                        minLength: 10,
                        maxLength: 10
                      })}
                    />
                    {errors?.phone?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập số điện thoại</p>}
                    {errors?.phone?.type === "minLength" && <p className="text-[#FF0000] text-sm mt-2">Số điện thoại không đúng định dạng</p>}
                  </div>
                  <div className="relative">
                    <p className="bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600
                absolute">Mật Khẩu</p>
                    <input type="password" className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md"
                      name="password"
                      {...register("password", {
                        required: true,
                        minLength: 6,
                      })}
                    />
                    {errors?.password?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng nhập mật khẩu </p>}
                    {errors?.password?.type === "minLength" && <p className="text-[#FF0000] text-sm mt-2">Mật khẩu ít nhất 6 ký tự </p>}
                  </div>
                  <div className="w-full flex justify-between flex-row mt-[20px] max-md:flex-col max-md:items-center max-md:gap-[15px]">
                    <div className="w-[125px] max-md:w-[100%]" onClick={() => document.querySelector('#btnFront').click()}>
                      <label className="block text-center text-sm font-medium leading-6 text-gray-900">Mặt trước CCCD</label>
                      <div className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 h-[100px] items-center justify-center flex pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md">
                        <img className={front ? 'h-full' : 'w-[30px]'} src={front ? URL.createObjectURL(front) : loadImg} alt="frontCCCD" />
                      </div>
                      {errors?.front?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng tải ảnh lên </p>}
                    </div>
                    <div className="w-[125px] max-md:w-[100%]" onClick={() => document.querySelector('#btnback').click()}>
                      <label className="block text-center text-sm font-medium leading-6 text-gray-900">Mặt sau CCCD</label>
                      <div className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 h-[100px] items-center justify-center flex pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md">
                        <img className={back ? 'h-full' : 'w-[30px]'} src={back ? URL.createObjectURL(back) : loadImg} alt="backCCCD" />
                      </div>
                      {errors?.back?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng tải ảnh lên </p>}
                    </div>
                    <div className="w-[125px] max-md:w-[100%]" onClick={() => document.querySelector('#btnAvt').click()}>
                      <label className="block text-center text-sm font-medium leading-6 text-gray-900">Chân dung</label>
                      <div className="border placeholder-gray-400 focus:outline-none
                focus:border-black w-full pt-4 h-[100px] items-center justify-center flex pr-4 pb-4 pl-4 mt-2 mr-0 mb-0 ml-0 text-base block bg-white
                border-gray-300 rounded-md">
                        <img className={avt ? 'h-full' : 'w-[30px]'} src={avt ? URL.createObjectURL(avt) : loadImg} alt="avatar" />
                      </div>
                      {errors?.avt?.type === "required" && <p className="text-[#FF0000] text-sm mt-2">Vui lòng tải ảnh lên </p>}
                    </div>
                  </div>
                  {/* input img hidden */}
                  <div hidden>
                    <input
                      id="btnFront"
                      type="file"
                      {...register("front", {
                        required: true,
                      })}
                      onChange={(event) => {
                        console.log(event.target.files[0]);
                        setFront(event.target.files[0]);
                      }}
                    />
                    <input
                      id="btnback"
                      type="file"
                      {...register("back", {
                        required: true,
                      })}
                      onChange={(event) => {
                        console.log(event.target.files[0]);
                        setBack(event.target.files[0]);
                      }}
                    />
                    <input
                      id="btnAvt"
                      type="file"
                      {...register("avt", {
                        required: true,
                      })}
                      onChange={(event) => {
                        console.log(event.target.files[0]);
                        setAvt(event.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="relative">
                    <button type="submit" className="w-full inline-block pt-4 pr-5 pb-4 pl-5 text-xl font-medium text-center text-white bg-indigo-500
                rounded-lg transition duration-200 hover:bg-indigo-600 ease">Đăng Ký</button>
                  </div>
                  <div className="w-full text-center">
                    Đã có tài khoản ? <a href="/login">Đăng Nhập</a>
                  </div>
                </div>
              </form>

            </div>
            <svg viewbox="0 0 91 91" className="absolute top-0 left-0 z-0 w-32 h-32 -mt-12 -ml-12 text-yellow-300
          fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
                cx="3.261" cy="3.445" r="2.72" /><circle cx="15.296" cy="3.445" r="2.719" /><circle cx="27.333" cy="3.445"
                  r="2.72" /><circle cx="39.369" cy="3.445" r="2.72" /><circle cx="51.405" cy="3.445" r="2.72" /><circle cx="63.441"
                    cy="3.445" r="2.72" /><circle cx="75.479" cy="3.445" r="2.72" /><circle cx="87.514" cy="3.445" r="2.719" /></g><g
                      transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72" /><circle cx="15.296" cy="3.525"
                        r="2.719" /><circle cx="27.333" cy="3.525" r="2.72" /><circle cx="39.369" cy="3.525" r="2.72" /><circle
                    cx="51.405" cy="3.525" r="2.72" /><circle cx="63.441" cy="3.525" r="2.72" /><circle cx="75.479" cy="3.525"
                      r="2.72" /><circle cx="87.514" cy="3.525" r="2.719" /></g><g transform="translate(0 24)"><circle cx="3.261"
                        cy="3.605" r="2.72" /><circle cx="15.296" cy="3.605" r="2.719" /><circle cx="27.333" cy="3.605" r="2.72" /><circle
                    cx="39.369" cy="3.605" r="2.72" /><circle cx="51.405" cy="3.605" r="2.72" /><circle cx="63.441" cy="3.605"
                      r="2.72" /><circle cx="75.479" cy="3.605" r="2.72" /><circle cx="87.514" cy="3.605" r="2.719" /></g><g
                        transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72" /><circle cx="15.296" cy="3.686"
                          r="2.719" /><circle cx="27.333" cy="3.686" r="2.72" /><circle cx="39.369" cy="3.686" r="2.72" /><circle
                    cx="51.405" cy="3.686" r="2.72" /><circle cx="63.441" cy="3.686" r="2.72" /><circle cx="75.479" cy="3.686"
                      r="2.72" /><circle cx="87.514" cy="3.686" r="2.719" /></g><g transform="translate(0 49)"><circle cx="3.261"
                        cy="2.767" r="2.72" /><circle cx="15.296" cy="2.767" r="2.719" /><circle cx="27.333" cy="2.767" r="2.72" /><circle
                    cx="39.369" cy="2.767" r="2.72" /><circle cx="51.405" cy="2.767" r="2.72" /><circle cx="63.441" cy="2.767"
                      r="2.72" /><circle cx="75.479" cy="2.767" r="2.72" /><circle cx="87.514" cy="2.767" r="2.719" /></g><g
                        transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72" /><circle cx="15.296" cy="2.846"
                          r="2.719" /><circle cx="27.333" cy="2.846" r="2.72" /><circle cx="39.369" cy="2.846" r="2.72" /><circle
                    cx="51.405" cy="2.846" r="2.72" /><circle cx="63.441" cy="2.846" r="2.72" /><circle cx="75.479" cy="2.846"
                      r="2.72" /><circle cx="87.514" cy="2.846" r="2.719" /></g><g transform="translate(0 73)"><circle cx="3.261"
                        cy="2.926" r="2.72" /><circle cx="15.296" cy="2.926" r="2.719" /><circle cx="27.333" cy="2.926" r="2.72" /><circle
                    cx="39.369" cy="2.926" r="2.72" /><circle cx="51.405" cy="2.926" r="2.72" /><circle cx="63.441" cy="2.926"
                      r="2.72" /><circle cx="75.479" cy="2.926" r="2.72" /><circle cx="87.514" cy="2.926" r="2.719" /></g><g
                        transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72" /><circle cx="15.296" cy="3.006"
                          r="2.719" /><circle cx="27.333" cy="3.006" r="2.72" /><circle cx="39.369" cy="3.006" r="2.72" /><circle
                    cx="51.405" cy="3.006" r="2.72" /><circle cx="63.441" cy="3.006" r="2.72" /><circle cx="75.479" cy="3.006"
                      r="2.72" /><circle cx="87.514" cy="3.006" r="2.719" /></g></g></g></g></svg>
            <svg viewbox="0 0 91 91" className="absolute bottom-0 right-0 z-0 w-32 h-32 -mb-12 -mr-12 text-indigo-500
          fill-current"><g stroke="none" strokewidth="1" fillrule="evenodd"><g fillrule="nonzero"><g><g><circle
                cx="3.261" cy="3.445" r="2.72" /><circle cx="15.296" cy="3.445" r="2.719" /><circle cx="27.333" cy="3.445"
                  r="2.72" /><circle cx="39.369" cy="3.445" r="2.72" /><circle cx="51.405" cy="3.445" r="2.72" /><circle cx="63.441"
                    cy="3.445" r="2.72" /><circle cx="75.479" cy="3.445" r="2.72" /><circle cx="87.514" cy="3.445" r="2.719" /></g><g
                      transform="translate(0 12)"><circle cx="3.261" cy="3.525" r="2.72" /><circle cx="15.296" cy="3.525"
                        r="2.719" /><circle cx="27.333" cy="3.525" r="2.72" /><circle cx="39.369" cy="3.525" r="2.72" /><circle
                    cx="51.405" cy="3.525" r="2.72" /><circle cx="63.441" cy="3.525" r="2.72" /><circle cx="75.479" cy="3.525"
                      r="2.72" /><circle cx="87.514" cy="3.525" r="2.719" /></g><g transform="translate(0 24)"><circle cx="3.261"
                        cy="3.605" r="2.72" /><circle cx="15.296" cy="3.605" r="2.719" /><circle cx="27.333" cy="3.605" r="2.72" /><circle
                    cx="39.369" cy="3.605" r="2.72" /><circle cx="51.405" cy="3.605" r="2.72" /><circle cx="63.441" cy="3.605"
                      r="2.72" /><circle cx="75.479" cy="3.605" r="2.72" /><circle cx="87.514" cy="3.605" r="2.719" /></g><g
                        transform="translate(0 36)"><circle cx="3.261" cy="3.686" r="2.72" /><circle cx="15.296" cy="3.686"
                          r="2.719" /><circle cx="27.333" cy="3.686" r="2.72" /><circle cx="39.369" cy="3.686" r="2.72" /><circle
                    cx="51.405" cy="3.686" r="2.72" /><circle cx="63.441" cy="3.686" r="2.72" /><circle cx="75.479" cy="3.686"
                      r="2.72" /><circle cx="87.514" cy="3.686" r="2.719" /></g><g transform="translate(0 49)"><circle cx="3.261"
                        cy="2.767" r="2.72" /><circle cx="15.296" cy="2.767" r="2.719" /><circle cx="27.333" cy="2.767" r="2.72" /><circle
                    cx="39.369" cy="2.767" r="2.72" /><circle cx="51.405" cy="2.767" r="2.72" /><circle cx="63.441" cy="2.767"
                      r="2.72" /><circle cx="75.479" cy="2.767" r="2.72" /><circle cx="87.514" cy="2.767" r="2.719" /></g><g
                        transform="translate(0 61)"><circle cx="3.261" cy="2.846" r="2.72" /><circle cx="15.296" cy="2.846"
                          r="2.719" /><circle cx="27.333" cy="2.846" r="2.72" /><circle cx="39.369" cy="2.846" r="2.72" /><circle
                    cx="51.405" cy="2.846" r="2.72" /><circle cx="63.441" cy="2.846" r="2.72" /><circle cx="75.479" cy="2.846"
                      r="2.72" /><circle cx="87.514" cy="2.846" r="2.719" /></g><g transform="translate(0 73)"><circle cx="3.261"
                        cy="2.926" r="2.72" /><circle cx="15.296" cy="2.926" r="2.719" /><circle cx="27.333" cy="2.926" r="2.72" /><circle
                    cx="39.369" cy="2.926" r="2.72" /><circle cx="51.405" cy="2.926" r="2.72" /><circle cx="63.441" cy="2.926"
                      r="2.72" /><circle cx="75.479" cy="2.926" r="2.72" /><circle cx="87.514" cy="2.926" r="2.719" /></g><g
                        transform="translate(0 85)"><circle cx="3.261" cy="3.006" r="2.72" /><circle cx="15.296" cy="3.006"
                          r="2.719" /><circle cx="27.333" cy="3.006" r="2.72" /><circle cx="39.369" cy="3.006" r="2.72" /><circle
                    cx="51.405" cy="3.006" r="2.72" /><circle cx="63.441" cy="3.006" r="2.72" /><circle cx="75.479" cy="3.006"
                      r="2.72" /><circle cx="87.514" cy="3.006" r="2.719" /></g></g></g></g></svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
